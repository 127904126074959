export const menuSidebar = [
  {
    id: 'orders',
    title: 'My orders',
    icon: 'paper-check',
    items: [
      {
        id: '1-1',
        title: 'Current orders',
        state: 'default',
        link: '/my-account/orders'
      },
      {
        id: '1-2',
        title: 'Completed orders',
        state: 'completed',
        link: '/my-account/orders?state=completed'
      }
    ]
  },
  {
    id: 'artwork-services',
    title: 'My Artwork Services',
    icon: 'printer',
    items: [
      {
        id: '2-1',
        title: 'Current artwork services',
        state: 'default',
        link: '/my-account/artwork-services'
      },
      {
        id: '2-2',
        title: 'Completed artwork services',
        state: 'completed',
        link: '/my-account/artwork-services?state=completed'
      }
    ]
  },
  // {
  //   id: 'design-services',
  //   title: 'My Design Services',
  //   icon: 'layout',
  //   items: [
  //     {
  //       id: '3-1',
  //       title: 'Current design services',
  //       state: 'default',
  //       link: '/my-account/design-services'
  //     },
  //     {
  //       id: '3-2',
  //       title: 'Completed design services',
  //       state: 'completed',
  //       link: '/my-account/design-services?state=completed'
  //     }
  //   ]
  // },
  {
    id: 'personal-infos',
    title: 'Personal Infos',
    icon: 'person',
    items: [
      {
        id: '4-1',
        title: 'Personal data infos',
        state: 'default',
        link: '/my-account/personal-infos'
      }
    ]
  }
]
